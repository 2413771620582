import React, { useEffect, useContext } from 'react'
import { HeadFC, navigate, PageProps } from 'gatsby'

// Models
import { ComicModel } from '../../../models'

// Icons
import { Email, Facebook, Instagram, Twitter } from '../../../assets/icons/'

// Components
import PageTemplate from '../PageTemplate'
import ComicImages from '../../organisms/ComicImages'
import DefaultHelmet from '../../atoms/DefaultHelmet'
import SimilarComics from '../../organisms/SimilarComics'
import VariantContext from '../../../context/Variant/Variant.context'

// Utils
import { capitalizeFirst, createPath } from '../../../utils/utils'

// Types
import { WarningKeys } from '../../../models/Comic.type'

// Styles
import {
  comicAge,
  comicTag,
  comicTop,
  comicTags,
  comicCity,
  comicLink,
  comicInfo,
  comicHalf,
  comicTeam,
  comicTeamTitle,
  comicTech,
  comicTechTitle,
  comicEmail,
  comicTitle,
  comicGenre,
  comicGenreLink,
  comicStatus,
  comicStatusContainer,
  comicAuthor,
  comicDetail,
  comicButton,
  comicMature,
  comicBottom,
  comicButtons,
} from './style.module.scss'

const ComicTemplate: React.FC<PageProps<any, ComicModel>> = ({
  pageContext: {
    name,
    age,
    tags,
    shop,
    link,
    vaki,
    city,
    cover,
    genres,
    detail,
    reader,
    status,
    patreon,
    endYear,
    authors,
    chapters,
    variants,
    warnings,
    publisher,
    email_shop,
    publicationYear,
  },
}) => {
  const getButtonText = () => {
    switch (reader.code) {
      case 'VS':
        return 'Leer online'
      case 'DS':
        return 'Descargar'
      case 'VR':
        return 'Compra digital'
      case 'PR':
        return 'Ver avance'
      default:
        return 'Leer'
    }
  }

  const showWarnings = () => {
    const warnMap = {
      blood: 'Sangre',
      violence: 'Violencia',
      language: 'Lenguaje fuerte',
      nude: 'Desnudos',
      substances: 'Uso de Sustancias',
      sex: 'Contenido Sexual',
    }
    const warnArr = Object.keys(warnings).filter(
      (k) => warnings[k as WarningKeys]
    )
    return warnArr.map((warning) => <p>{warnMap[warning as WarningKeys]}</p>)
  }

  const renderTeam = () => {
    return authors.map((author) => {
      return (
        <div
          key={`author_socials_${author.id}`}
          onClick={() => {
            navigate(
              `/personas/${author.id}-${createPath(
                author.pseudonym || (author.fullname as string)
              )}`
            )
          }}
        >
          <p>
            <span className={comicAuthor}>
              {author.pseudonym || author.fullname}
            </span>
            {author.role && ' - '}
            <b>{author.role}</b>
          </p>
          {author.facebook && (
            <a
              href={`https://www.facebook.com/${author.facebook}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className={comicLink}>
                <Facebook />
                <div>{author.facebook}</div>
              </div>
            </a>
          )}
          {author.instagram && (
            <a
              href={`https://www.instagram.com/${author.instagram}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className={comicLink}>
                <Instagram />
                <div>{author.instagram}</div>
              </div>
            </a>
          )}
          {author.twitter && (
            <a
              href={`https://www.twitter.com/${author.twitter}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className={comicLink}>
                <Twitter />
                <div>{author.twitter}</div>
              </div>
            </a>
          )}
        </div>
      )
    })
  }

  const { updateVariant } = useContext(VariantContext)
  useEffect(() => {
    updateVariant(null)
  }, [])

  return (
    <PageTemplate>
      <h1 className={comicTitle}>{name}</h1>
      <div className={comicTop}>
        <ComicImages name={name} cover={cover} variants={variants} />
        <div className={comicInfo}>
          <div className={comicDetail}>{detail}</div>
          <div className={[comicTech, comicHalf].join(' ')}>
            <h3 className={[comicTitle, comicTechTitle].join(' ')}>
              Información
            </h3>
            {city && city.code !== 'UKN' && (
              <div className={comicCity}>
                {city?.code !== 'UKN' ? city.name : ''}
              </div>
            )}
            {publicationYear && (
              <div className={comicCity}>
                {publicationYear}
                {endYear ? ` - ${endYear}` : ''}
              </div>
            )}
            <p>Editorial</p>
            <p>
              <b
                className={comicGenreLink}
                onClick={() => {
                  navigate(
                    `/editoriales/${publisher.id}-${createPath(
                      publisher.name as string
                    )}`
                  )
                }}
              >
                {publisher.name}
              </b>
            </p>
            {chapters && (
              <>
                <p>Capítulos</p>
                <p>
                  <b>{chapters}</b>
                </p>
              </>
            )}
            {genres?.length > 0 && (
              <>
                <p>{genres.length === 1 ? 'Género' : 'Géneros'}</p>
                <div className={comicGenre}>
                  {genres.map((genre) => (
                    <div
                      key={`genre_${genre.id}`}
                      className={comicGenreLink}
                      onClick={() => {
                        navigate(
                          `/generos/${genre.id}-${createPath(genre.name)}`
                        )
                      }}
                    >
                      <b>{genre.name}</b>
                    </div>
                  ))}
                </div>
              </>
            )}
            {age.code !== 'UK' && (
              <>
                <div className={comicAge}>
                  Edad:{' '}
                  <b className={age.code === 'MT' ? comicMature : ''}>
                    {age.name}
                  </b>
                </div>
                {warnings && Object.values(warnings).some((v) => v) && showWarnings()}
              </>
            )}
            <div className={comicStatusContainer}>
              <div
                className={comicStatus}
                style={{ background: status.color }}
              />
              {status.name}
            </div>
            <div className={comicTags}>
              {tags.other?.split(',').map((tag, index) => {
                if (index < 7)
                  return (
                    <div className={comicTag}>
                      {capitalizeFirst(tag.trim())}
                    </div>
                  )
                return null
              })}
            </div>
          </div>
        </div>
      </div>
      {(shop || vaki || link || patreon || email_shop) && (
        <>
          <h2 className={comicTitle}>Donde conseguir</h2>
          {(shop || vaki || link || patreon) && (
            <div className={comicButtons}>
              {link && (
                <a target="_blank" rel="noreferrer" href={link}>
                  <div className={comicButton}>{getButtonText()}</div>
                </a>
              )}
              {shop && (
                <a target="_blank" rel="noreferrer" href={shop}>
                  <div className={comicButton}>Comprar</div>
                </a>
              )}
              {vaki && (
                <a target="_blank" rel="noreferrer" href={vaki}>
                  <div className={comicButton}>Vaki</div>
                </a>
              )}
              {patreon && (
                <a target="_blank" rel="noreferrer" href={patreon}>
                  <div className={comicButton}>Patreon</div>
                </a>
              )}
            </div>
          )}
          {email_shop && (
            <p
              className={comicEmail}
              style={{ marginTop: shop || vaki || link || patreon ? '8px' : 0 }}
            >
              <b>Comprar por e-mail:</b> {email_shop}
            </p>
          )}
        </>
      )}
      <div className={comicBottom}>
        {authors?.length > 0 && (
          <div className={comicTeam}>
            <h2 className={[comicTitle, comicTeamTitle].join(' ')}>
              {authors.length > 1 ? 'Equipo' : 'Autor'}
            </h2>
            {renderTeam()}
          </div>
        )}
        {(publisher.email ||
          publisher.facebook ||
          publisher.instagram ||
          publisher.twitter) && (
          <div className={comicTech}>
            <h3 className={[comicTitle, comicTechTitle].join(' ')}>
              Datos de contacto
            </h3>
            {publisher.instagram && (
              <a
                href={`https://www.instagram.com/${publisher.instagram}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className={comicLink}>
                  <Instagram />
                  <div>{publisher.instagram}</div>
                </div>
              </a>
            )}
            {publisher.facebook && (
              <a
                href={`https://www.facebook.com/${publisher.facebook}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className={comicLink}>
                  <Facebook />
                  <div>{publisher.facebook}</div>
                </div>
              </a>
            )}
            {publisher.twitter && (
              <a
                href={`https://www.twitter.com/${publisher.twitter}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className={comicLink}>
                  <Twitter />
                  <div>{publisher.twitter}</div>
                </div>
              </a>
            )}
            {publisher.email && (
              <div className={comicLink}>
                <Email />
                <div className="mt-0 mb-0">{publisher.email}</div>
              </div>
            )}
          </div>
        )}
      </div>
      <SimilarComics tags={tags?.all as string} />
    </PageTemplate>
  )
}

export const Head: HeadFC<any, ComicModel> = ({
  pageContext: {
    name,
    detail,
    tags: { all },
  },
}) => (
  <>
    <title>{name} - Comic Colombiano</title>
    <meta
      name="description"
      content={
        detail ??
        '¡Novelas gráficas, revistillas, webcómics y más! Conoce aquí el catálogo más grande de cómic colombiano y sus autores. ¡La inscripción es gratuita!'
      }
    />
    <meta
      name="keywords"
      content={[
        all,
        'colombiano,comic,base,de,datos,comics,webcomics,webcomic,novela,grafica,arte,revistillas,catalogo',
      ].join(',')}
    />
    <DefaultHelmet />
  </>
)

export default ComicTemplate
