// extracted by mini-css-extract-plugin
export var comicAge = "style-module--comic-age--2f58c";
export var comicAuthor = "style-module--comic-author--5b1d8";
export var comicBottom = "style-module--comic-bottom--a9973";
export var comicButton = "style-module--comic-button--1a67d";
export var comicButtons = "style-module--comic-buttons--4b8fa";
export var comicCity = "style-module--comic-city--50833";
export var comicDetail = "style-module--comic-detail--c8aaf";
export var comicEmail = "style-module--comic-email--a36d5";
export var comicExtra = "style-module--comic-extra--1aa75";
export var comicGenre = "style-module--comic-genre--007b3";
export var comicGenreLink = "style-module--comic-genre-link--f4ed6";
export var comicHalf = "style-module--comic-half--270bd";
export var comicInfo = "style-module--comic-info--152fe";
export var comicLink = "style-module--comic-link--7309b";
export var comicMature = "style-module--comic-mature--21932";
export var comicStatus = "style-module--comic-status--df5ba";
export var comicStatusContainer = "style-module--comic-status-container--3d499";
export var comicTag = "style-module--comic-tag--ed2b3";
export var comicTags = "style-module--comic-tags--ffdda";
export var comicTeam = "style-module--comic-team--2df56";
export var comicTeamTitle = "style-module--comic-team-title--ff42a";
export var comicTech = "style-module--comic-tech--adb7b";
export var comicTechTitle = "style-module--comic-tech-title--4b9ee";
export var comicTitle = "style-module--comic-title--17e79";
export var comicTop = "style-module--comic-top--84fb6";